@media (min-width: 1280px) {
  .team {
    grid-template-areas:
      "a b c d ."
      ". e f g h"
      "i j k l .";
  }
  
  #member-0 {
    grid-area: a;
  }
  
  #member-1 {
    grid-area: b;
  }
  
  #member-2 {
    grid-area: c;
  }
  
  #member-3 {
    grid-area: d;
  }
  
  #member-4 {
    grid-area: e;
  }
  
  #member-5 {
    grid-area: f;
  }
  
  #member-6 {
    grid-area: g;
  }
  
  #member-7 {
    grid-area: h;
  }
  
  #member-8 {
    grid-area: i;
  }
  
  #member-9 {
    grid-area: j;
  }
  
  #member-10 {
    grid-area: k;
  }
  
  #member-11 {
    grid-area: l;
  }
}