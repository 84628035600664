.prose-minimal * {
  @apply !m-0 text-sm leading-normal;
}

.prose-links-icon a {
  @apply font-medium;
}

.prose-links-icon a:after {
  @apply inline-block w-5 h-5 bg-lilac-300 rounded-full ml-1 align-middle text-xs text-center leading-5 no-underline;

  content: '\2192';
}

.lw-not-prose-iframe {
  @apply w-full
}

@media (min-width: 768px) {
  .lw-not-prose-iframe {
    width:revert-layer;
  }
}
