.rich-title img {
    display: inline-block;
    max-width: 80px;
    vertical-align: middle;
}

@media (max-width: 648px) { 
  .rich-title .hide {
    display: none;
  }
}
